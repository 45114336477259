import * as React from "react";
import classes from "./feedback.module.scss";
import pic1 from "./images/pic1.png";
import pic2 from "./images/pic2.png";
import AIALogo from "./images/aiaLogo.png";

const Feedback = () => {
  return (
    <div className={classes.root}>
      <div className={classes.customer}>
        <img src={AIALogo} className={classes.customerLogo} />
        <p className={classes.customerName}>
          AIA đã sử dụng dịch vụ của Curnon
        </p>
      </div>
      <img src={pic1} className={classes.pic1} />
      <img src={pic2} className={classes.pic2} />
      <div className={classes.feedback}>
        <p className={classes.quote}>
          Các MDRT, COT, TOT 2021 của AIA những ngày qua đã được công ty gửi đến
          bộ quà tặng đẳng cấp bao gồm 1 chiếc đồng hồ sang trọng, thanh lịch
          đến từ thương hiệu Curnon nổi tiếng với thiết kế dành riêng cho những
          tinh hoa của AIA. Với sự chỉnh chu đến từng chi tiết và là những phiên
          bản giới hạn, AIA mong mang đến những trải nghiệm tốt nhất và tinh tế
          nhất cho những cá nhân xuất sắc.
        </p>
      </div>
    </div>
  );
};

export default Feedback;
