import * as React from "react";
import classes from "./gallery.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";
import Image1 from "./images/1.png";
import Image2 from "./images/2.png";
import Image3 from "./images/3.png";
import Image4 from "./images/4.png";
import Image5 from "./images/5.png";
import Image6 from "./images/6.png";
import Image7 from "./images/7.png";
import Image8 from "./images/8.png";
import Image9 from "./images/9.png";
import Image10 from "./images/10.png";
import Image11 from "./images/11.png";
import Image12 from "./images/12.png";
import Image13 from "./images/13.png";
import Image14 from "./images/14.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Pagination, Navigation, Scrollbar]);

const images = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14,
];

const Gallery = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  return (
    <div className={classNames("gallery-module", classes.root)}>
      <div className={classes.title}>
        <span>{`Thư viện\nhình ảnh`}</span>
      </div>
      <div className={classes.row}>
        <div className={classes.images}>
          <span className={classes.empty}></span>
          <Swiper
            slidesPerView="auto"
            spaceBetween={20}
            scrollbar={{ draggable: true }}
          >
            {images.map((image, idx) => {
              return (
                <SwiperSlide key={idx} slideClass>
                  <img
                    src={image}
                    alt={`gallery-${idx}`}
                    style={{ width: 277, cursor: "pointer" }}
                    className={classes.image}
                    onClick={() => {
                      setPhotoIndex(idx);
                      setIsOpen(true);
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <span className={classes.empty}></span>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + images.length - 1) % images.length,
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex) => (photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default Gallery;
