import * as React from "react";
import Button from "../Button";
import classes from "./process.module.scss";
import contact from "../../assets/images/contact.svg";
import advisory from "../../assets/images/advisory.svg";
import bling from "../../assets/images/bling.svg";
import { useAppContext } from "../../context/app";

const Process = () => {
  const [, { toggleDrawer }] = useAppContext();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <p className={classes.text1}>
            Quy trình tư vấn và thiết kế giải pháp
          </p>
          <p className={classes.text2}>Theo nhu cầu doanh nghiệp</p>
          <Button onClick={() => toggleDrawer("download")}>Liên hệ ngay</Button>
        </div>
        <div className={classes.steps}>
          <div className={classes.step}>
            <img src={contact} />
            <div className={classes.content}>
              <p className={classes.title}>Liên hệ</p>
              <p className={classes.description}>
                Điền thông tin và chuyên viên tư vấn của Curnon sẽ liên hệ lại
                với bạn trong thời gian sớm nhất
              </p>
            </div>
          </div>

          <div className={classes.step}>
            <img src={advisory} />
            <div className={classes.content}>
              <p className={classes.title}>Tư vấn & thiết kế</p>
              <p className={classes.description}>
                Cùng bạn lựa chọn giải pháp, tùy biến sản phẩm và thời gian phù
                hợp
              </p>
            </div>
          </div>

          <div className={classes.step}>
            <img src={bling} />
            <div className={classes.content}>
              <p className={classes.title}>Hoàn thiện</p>
              <p className={classes.description}>
                Để lại ấn tượng khó phai với đối tác và các thành viên trong
                doanh nghiệp
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
