import * as React from "react";
import classes from "./bundle.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import ChevronLeftIcon from "../../assets/images/chevron-left.svg";
import ChevronRightIcon from "../../assets/images/chevron-right.svg";
import "swiper/components/pagination/pagination.scss";
import classNames from "classnames";
import { useAppContext } from "../../context/app";

import Image1 from "./images/1.png";
import Image2 from "./images/2.png";
import Image3 from "./images/3.png";
import Image4 from "./images/4.png";
import Image5 from "./images/5.png";
import Button from "../Button";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";
import useWindowSize from "../../hooks/useWindowSize";
import { useMemo } from "react";

SwiperCore.use([Pagination, Navigation]);

const bundles = [
  {
    name: "The Classic",
    info: "Đồng hồ, ví da",
    items: ["Watch", "Leather Wallet"],
    image: Image1,
  },
  {
    name: "The Executive",
    info: "Đồng hồ, bút & cà vạt",
    items: ["Watch", "Pen", "Tie"],
    image: Image2,
  },
  {
    name: "The Keeper",
    info: "Đồng hồ, bút & móc chìa khóa",
    items: ["Watch", "Pen", "Key Ring"],
    image: Image3,
  },
  {
    name: "The Thinker",
    info: "Đồng hồ, bút & sổ tay",
    items: ["Watch", "Pen", "Notebook"],
    image: Image5,
  },
  {
    name: "The Clever",
    info: "Đồng hồ, kính mắt",
    items: ["Watch", "Anti Bluelight Frame"],
    image: Image4,
  },
];

const Bundle = () => {
  const sliderRef = React.useRef();
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  const [activeIndex, setActiveIndex] = React.useState(0);

  const { width, height } = useWindowSize();

  const isDesktop = width >= 1280;

  console.log("activeIndex: ", activeIndex);

  const [, { toggleDrawer }] = useAppContext();
  const currentPackage = useMemo(() => {
    return bundles[activeIndex];
  }, [activeIndex]);
  return (
    <div className={classes.root}>
      <p className={classes.title}>{`Các gói\nsản phẩm`}</p>
      <p className={classes.subTitle}>
        {`Curnon mang đến sự kết hợp của ngôn ngữ thiết kế hiện đại, chất lượng
        chuẩn Quốc tế và khả năng tuỳ biến linh hoạt.`}
      </p>
      <div className={classes.bundlesMobile}>
        <Swiper
          onActiveIndexChange={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
          breakpoints={{
            1280: {
              slidesPerView: 5,
            },
          }}
          navigation={{
            prevEl: prevRef.current ? prevRef.current : undefined,
            nextEl: nextRef.current ? nextRef.current : undefined,
          }}
          centeredSlides={true}
          slideActiveClass="activeClass"
          slidesPerView={1}
          slideToScroll={1}
          loop={true}
          // onChange={(swiper) => {
          //   swiper.navigation.update();
          // }}
          // onInit={(swiper) => {
          //   swiper.params.navigation.prevEl = prevRef.current;
          //   swiper.params.navigation.nextEl = nextRef.current;
          //   swiper.navigation.update();
          // }}
          onSwiper={(s) => (sliderRef.current = s)}
          slideToClickedSlide={true}
        >
          {bundles.map((bundle, idx) => {
            return (
              <SwiperSlide key={idx}>
                {(slideProps) => {
                  const { isPrev, isNext, isActive } = slideProps;
                  return (
                    <div
                      className={classNames(classes.slide, {
                        [classes.activeClass]: activeIndex == idx,
                        [classes.prevSlide]: isPrev,
                        [classes.nextSlide]: isNext,
                      })}
                    >
                      <div className={classes.placeholder}>
                        <img
                          className={classes.image}
                          src={bundle.image}
                          alt="slide"
                        />
                      </div>
                    </div>
                  );
                }}
              </SwiperSlide>
            );
          })}
          <div ref={prevRef} className={classes.prevBtn}>
            <img src={ChevronLeftIcon} alt="prev arrow" />
          </div>
          <div ref={nextRef} className={classes.nextBtn}>
            <img src={ChevronRightIcon} alt="next arrow" />
          </div>
        </Swiper>
      </div>
      <div className={classes.packageInfo}>
        <div className={classes.packageName}>{currentPackage?.name}</div>
        <div className={classes.packageInfo}>{currentPackage?.info}</div>
      </div>
      <div className={classes.bottomIndicator}>
        <div
          className={classes.bottomIndicatorItem}
          style={{ transform: `translateX(${activeIndex * 100}%)` }}
        ></div>
      </div>
      <div className={classes.btnWrapper}>
        <Button onClick={() => toggleDrawer("download")}>Tải tài liệu</Button>
      </div>
    </div>
  );
};

export default Bundle;

// 3-2
// 4-3
// 5-4
// 6-0
// 7-1

// 1-0
// 2-1
