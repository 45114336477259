import * as React from "react";
import Button from "../Button";
import classes from "./services.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";

import SwiperCore, { Navigation } from "swiper";

import S1_1 from "./images/1_1.jpg";
import S1_2 from "./images/1_2.jpg";
import S2_1 from "./images/2_1.jpg";
import S2_2 from "./images/2_2.jpg";
import S3_1 from "./images/3_1.jpg";

import ChevronLeftIcon from "../../assets/images/chevron-left.svg";
import ChevronRightIcon from "../../assets/images/chevron-right.svg";
import { useAppContext } from "../../context/app";

SwiperCore.use([Navigation]);

const Slider = ({ title, description, images, image, leftColor }) => {
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  const showArrow = images.length > 1;
  return (
    <div className={classes.slider}>
      <Swiper
        navigation={{
          prevEl: prevRef.current ? prevRef.current : undefined,
          nextEl: nextRef.current ? nextRef.current : undefined,
        }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.update();
        }}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={idx}>
            <img alt="img" src={image} className={classes.image} />
          </SwiperSlide>
        ))}
        {showArrow && (
          <div ref={prevRef} className={classes.prevBtn}>
            <img src={ChevronLeftIcon} alt="prev" />
          </div>
        )}
        {showArrow && (
          <div ref={nextRef} className={classes.nextBtn}>
            <img src={ChevronRightIcon} alt="next" />
          </div>
        )}
      </Swiper>
      <div
        className={classes.content}
        style={{
          "--left-color": leftColor,
        }}
      >
        <p className={classes.sliderTitle}>{title}</p>
        <p className={classes.sliderDescription}>{description}</p>
      </div>
    </div>
  );
};

const Services = () => {
  const [, { toggleDrawer }] = useAppContext();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.info}>
          <div className={classes.titleWrapper}>
            <p className={classes.title}>Các gói dịch vụ</p>
            <p className={classes.description}>
              Curnon mang đến sự kết hợp của ngôn ngữ thiết kế hiện đại, chất
              lượng chuẩn Quốc tế và khả năng tuỳ biến linh hoạt.
            </p>
          </div>
          <div className={classes.btnWrapper}>
            <Button onClick={() => toggleDrawer("download")}>
              Tải tài liệu
            </Button>
          </div>
        </div>
        <div className={classes.services}>
          <Slider
            title="Dịch vụ 1"
            description="ĐỒNG HỒ CURNON + BAO BÌ THIẾT KẾ"
            leftColor={"linear-gradient(180deg, #e96281 0%, #ea6c59 100%)"}
            images={[S1_1, S1_2]}
          />
          <Slider
            title="Dịch vụ 2"
            description="ĐỒNG HỒ THIẾT KẾ + BAO BÌ THIẾT KẾ"
            leftColor={"linear-gradient(180deg, #f8cb6f 0%, #fef184 100%)"}
            images={[S2_1, S2_2]}
          />
          <Slider
            title="Dịch vụ 3"
            description="DÒNG SẢN PHẨM KHÁC"
            leftColor={"linear-gradient(180deg, #574ef1 0%, #983be6 100%)"}
            images={[S3_1, S3_1]}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
