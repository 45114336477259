import * as React from "react";
import classes from "./aboutUs.module.scss";
import arrowRightCircle from "../../assets/images/arrow-right-circle.svg";

const AboutUs = () => {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.about}>
          <p className={classes.title}>Về chúng tôi</p>
          <p className={classes.description}>
            Curnon là thương hiệu Đồng hồ đầu tiên của Việt Nam. Trải qua 4 năm
            phát triển và tăng trưởng mạnh mẽ, cùng đội ngũ thiết kế sáng tạo,
            am hiểu thị trường và năng lực giám sát nghiêm ngặt về chất lượng.
          </p>
          <a className={classes.link} href="https://curnonwatch.com/">
            <img src={arrowRightCircle} /> Tham quan curnonwatch.com
          </a>
        </div>
        <div className={classes.statistic}>
          <div className={classes.section}>
            <p className={classes.title}>4 năm+</p>
            <p className={classes.description}>tăng trưởng mạnh mẽ</p>
          </div>
          <div className={classes.section}>
            <p className={classes.title}>50,000+</p>
            <p className={classes.description}>khách hàng</p>
          </div>
          <div className={classes.section}>
            <p className={classes.title}>100+</p>
            <p className={classes.description}>mẫu mã sản phẩm đa dạng</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
