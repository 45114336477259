import * as React from "react";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import Products from "../components/Products";
import Feedback from "../components/Feedback";
import Bundle from "../components/Bundle";
import Gallery from "../components/Gallery";
import Process from "../components/Process";
import "../styles/index.scss";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Bussiness Packages | Curnon Watch</title>
        <meta
          name="description"
          content="Always prepared, Curnon Watch is with you at every step of the journey."
        ></meta>
      </Helmet>
      <Hero />
      <AboutUs />
      <Services />
      {/* <Products /> */}
      <Bundle />
      <Gallery />
      <Feedback />
      <Process />
    </>
  );
};

export default IndexPage;
