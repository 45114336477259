import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import Img from "gatsby-image";
import classes from "./hero.module.scss";
import Button from "../Button";
import { useAppContext } from "../../context/app";

const query = graphql`
  query {
    banner: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 375) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerDesktop: file(relativePath: { eq: "bannerDesktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);

  const sources = [
    data.banner.childImageSharp.fluid,
    {
      ...data.bannerDesktop.childImageSharp.fluid,
      media: `(min-width: 1280px)`,
    },
  ];

  const [, { toggleDrawer }] = useAppContext();

  return (
    <div className={classes.root}>
      <Img fluid={sources} />
      <div className={classes.content}>
        <p className={classes.title}>Giải pháp quà tặng doanh nghiệp</p>
        <p className={classes.description}>
          Giúp các doanh nghiệp dễ dàng lựa chọn những sản phẩm Đồng hồ và Phụ
          kiện phù hợp nhất.
        </p>
        <div className={classes.buttonWrapper}>
          <Button onClick={() => toggleDrawer("download")}>Tải tài liệu</Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
