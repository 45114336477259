import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./products.module.scss";
import pic1 from "./images/pic1.svg";
import pic2 from "./images/pic2.svg";
import classNames from "classnames";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper";

import "swiper/swiper.scss";

// SwiperCore.use([Virtual]);

const menWatches = [
  {
    id: 1,
    name: "Kashmir",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 2,
    name: "COLOSSEUM",
    size: "42mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic2,
  },
  {
    id: 3,
    name: "MYKONOS",
    size: "40mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic1,
  },
  {
    id: 4,
    name: "WEIMAR",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
  {
    id: 5,
    name: "Detroit",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 6,
    name: "Template",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
];

const womenWatches = [
  {
    id: 1,
    name: "Kashmir",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 2,
    name: "COLOSSEUM",
    size: "42mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic2,
  },
  {
    id: 3,
    name: "MYKONOS",
    size: "40mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic1,
  },
  {
    id: 4,
    name: "WEIMAR",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
  {
    id: 5,
    name: "Detroit",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 6,
    name: "Template",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
];

const accessories = [
  {
    id: 1,
    name: "Kashmir",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 2,
    name: "COLOSSEUM",
    size: "42mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic2,
  },
  {
    id: 3,
    name: "MYKONOS",
    size: "40mm",
    strap: "Dây da / dây thép không gỉ",
    imageURI: pic1,
  },
  {
    id: 4,
    name: "WEIMAR",
    size: "40mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
  {
    id: 5,
    name: "Detroit",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic1,
  },
  {
    id: 6,
    name: "Template",
    size: "42mm",
    strap: "Dây da / dây kim loại",
    imageURI: pic2,
  },
];

const Tabs = () => {
  const [activeTab, setActiveTab] = React.useState("menWatches");

  // store swiper instance
  const [swiper, setSwiper] = React.useState(null);

  const slideTo = React.useCallback(
    (index) => {
      if (swiper) swiper.slideToLoop(index);
    },
    [swiper]
  );

  const data = React.useMemo(() => {
    if (activeTab === "menWatches") return menWatches;
    if (activeTab === "womenWatches") return womenWatches;
    if (activeTab === "accessories") return accessories;

    return [];
  }, [activeTab]);

  React.useEffect(() => {
    slideTo(0);
  }, [slideTo, activeTab]);

  return (
    <div className={classes.tabs}>
      <div className={classes.header}>
        <nav className={classes.nav}>
          <a
            className={classNames(classes.navItem, {
              [classes.active]: activeTab === "menWatches",
            })}
            onClick={() => setActiveTab("menWatches")}
          >
            Đồng hồ nam
          </a>
          <a
            className={classNames(classes.navItem, {
              [classes.active]: activeTab === "womenWatches",
            })}
            onClick={() => setActiveTab("womenWatches")}
          >
            Đồng hồ nữ
          </a>
          <a
            className={classNames(classes.navItem, {
              [classes.active]: activeTab === "accessories",
            })}
            onClick={() => setActiveTab("accessories")}
          >
            Phụ kiện thời trang
          </a>
        </nav>
      </div>
      <div className={classes.content}>
        <div className={classes.slider}>
          <Swiper
            // initialSlide={0}
            // className="slider"
            // slideClass="slide"
            // slideActiveClass="active"
            breakpoints={{
              325: {
                // centeredSlides: false,
                // loop: false,
                // slidesPerView: 2,
                // initialSlide: 2,
              },
              // when window width is >= 640px
              1280: {
                // initialSlide: 1,
                // pagination: {
                //   el: ".swiper-pagination",
                //   clickable: true,
                // },
                // navigation: {
                //   nextEl: ".swiper-button-next",
                //   prevEl: ".swiper-button-prev",
                // },
                // width: 640,
                centeredSlides: true,
                slidesPerView: 4,
                loop: true,
                // loopedSlides:0,
                // spaceBetween: 30,
              },
            }}
            // navigation={{
            //   prevEl: prevRef.current ? prevRef.current : undefined,
            //   nextEl: nextRef.current ? nextRef.current : undefined,
            // }}
            // spaceBetween={50}
            // slidesPerGroup={2}
            loop={true}
            slidesPerView={2}
            onSlideChange={() => console.log("slide change")}
            onSwiper={setSwiper}
            // onInit={(swiper) => {
            //   swiper.params.navigation.prevEl = prevRef.current;
            //   swiper.params.navigation.nextEl = nextRef.current;
            //   swiper.navigation.update();
            // }}
          >
            {data.map((watch) => (
              <SwiperSlide key={watch.id}>
                <div className={classes.slide}>
                  <p className={classes.name}>{watch.name}</p>
                  <img
                    src={watch.imageURI}
                    alt={watch.name}
                    className={classes.image}
                  />
                  <p className={classes.size}>{watch.size}</p>
                  <p className={classes.strap}>{watch.strap}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

const Products = () => {
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{`Sản phẩm
  Curnon`}</h3>
      <Tabs />
    </div>
  );
};

export default Products;
